<template>
  <div class="mobile-bottom__menu">
    <div class="container-padding">
      <nav class="mobile-bottom__menu-nav">
        <component
          :is="item.action ? 'button' : 'router-link'"
          v-for="(item, i) in links"
          :key="i"
          :to="item.route"
          @click.prevent="item.action"
          class="mobile-bottom__menu-link btn"
          :class="{ active: isRouteActive(item.route) }"
        >
          <RemixIconComponent :category="item.category" :name="item.icon" />
          <span v-if="item.cart && totalCartPrice">{{ totalCartPrice | formatPrice }}</span>
          <span v-else>{{ item.title }}</span>
        </component>
      </nav>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import CartModal from "components/modals/components/CartModal.vue";
import CatalogMenu from "components/menus/components/CatalogMenu.vue";

export default {
  name: "MobileBottomMenuComponent",
  components: { RemixIconComponent },
  data() {
    return {
      links: [
        {
          category: "buildings",
          icon: "store-2-line",
          title: "Главная",
          route: { name: "home" },
        },
        {
          category: "others",
          icon: "products-line",
          title: "Каталог",
          route: { name: "catalog" },
          action: () => {
            this.$store.state._menus.push({
              component: CatalogMenu,
            });
          },
        },
        {
          category: "finance",
          icon: "shopping-cart-line",
          title: "Корзина",
          cart: true,
          action: () => {
            this.$store.state._menus.push({
              component: CartModal,
              alignRight: true,
            });
          },
        },
        // {
        //   category: "health",
        //   icon: "heart-line",
        //   title: "Наборы",
        //   route: { name: "sets" },
        // },
        {
          category: "user",
          icon: "user-line",
          title: "Профиль",
          route: { name: "profile" },
        },
      ],
    };
  },
  methods: {
    isRouteActive(route) {
      return route && this.$route.name === route.name && this.$route?.params?.link === route?.params?.link;
    },
  },
  computed: {
    totalCartPrice() {
      return this.totalProductPriceCart + this.totalSetPriceCart;
    },
    cartItems() {
      return this.$store.state.auth.cart;
    },
    totalProductPriceCart() {
      return this.$store.getters.total_product_price_cart(this.cartItems);
    },
    totalSetPriceCart() {
      return this.$store.getters.total_set_price_cart(this.cartItems);
    },
  },
};
</script>

<style lang="stylus">
.mobile-bottom__menu {
  position fixed
  bottom 0
  left 0
  right 0
  z-index 100
  height var(--bottom-menu-height)
  background var(--white)
  display flex
  border-top: 1px solid var(--border-color);
  +above(769px) {
    display none
  }

  &-nav {
    display flex
    justify-content center
    gap 7.5px
    margin 0 auto
    height 100%
  }

  &-link {
    display flex
    flex-direction column
    align-items center
    justify-content center
    font-weight 500
    font-size 0.625em
    line-height: 11px;
    color var(--gray-5)
    width 60px
    height 100%
    padding 5px
    flex-shrink 0
    border-radius var(--main-radius)

    .icon {
      width 24px
      height 24px

      svg path {
        fill var(--dark)
      }
    }

    &.active {
      color var(--main)

      .icon svg path {
        fill var(--main)
      }
    }
  }
}
</style>
